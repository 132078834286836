<template>
  <div class="bg-app vh-100 forms pt-0" :class="$vuetify.breakpoint.xs ? 'py-0' : 'py-5'">
    <v-container class="py-0" :class="$vuetify.breakpoint.xs ? 'px-1' : 'px-3'" fluid>
      <v-alert dense v-if="!$online" type="warning" icon="mdi-alert" class="alert-offline text-headline">
        {{ $t("t_no_connection") }}
      </v-alert>
      <div v-else>
        <v-card flat tile>
          <v-card-text class="d-flex pb-0">
            <p class="headline mb-0">{{ $t("v_account.t_account_settings") }}</p>
          </v-card-text>
        </v-card>

        <v-card class="mt-6">
          <v-toolbar elevation="0">
            <v-toolbar-title
              ><router-link to="/issue" style="color: inherit; text-decoration: none">{{
                $t("v_account.t_preferences")
              }}</router-link></v-toolbar-title
            >
          </v-toolbar>
          <v-card-text class="px-6">
            <v-row class="pb-2" style="align-items: center">
              <v-col cols="12">
                <TheLanguageSwitcher></TheLanguageSwitcher>
              </v-col>
              <v-col cols="6">
                <v-switch v-if="settings" :input-value="$vuetify.theme.dark" @click="changeDarkMode">
                  <template v-slot:label>
                    <span class="pl-3 text--primary">{{ $t("v_account.t_dark_mode") }}</span>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-if="settings"
                  :input-value="settings.timeFormat24Hour"
                  @click="changeTimeFormat"
                  :hint="$t('v_account.t_24_hour')"
                  persistent-hint
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col cols="12">
                <v-select
                  @change="changeTimeZone"
                  v-model="settings.timeZone"
                  :items="timeZones"
                  item-text="text"
                  item-value="value"
                  :label="$t('v_account.t_set_preferred_timezone')"
                  class="mt-1"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                      <v-list-item-subtitle v-if="item.offset">{{ item.offset }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card>
          <v-toolbar elevation="0">
            <v-toolbar-title>{{ $t("v_account.t_user_profile") }}</v-toolbar-title>
          </v-toolbar>
          <v-alert v-if="notificationProfile" type="error" class="ma-4">
            {{ notificationProfile }}
          </v-alert>
          <v-card-text class="pt-6">
            <v-form ref="registerForm" v-model="formValid">
              <TheImageUploader />
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="fields.firstName" :rules="[rules.required]" maxlength="20" required>
                    <template #label
                      >{{ $t("v_account.t_first_name") }} <span class="required"><strong>*</strong></span></template
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="fields.lastName" :rules="[rules.required]" maxlength="20" required
                    ><template #label
                      >{{ $t("v_account.t_last_name") }} <span class="required"><strong>*</strong></span></template
                    ></v-text-field
                  >
                </v-col>
                <v-col cols="12">
                  <label class="v-label theme--light">{{
                    $t("c_phoneinput.t_select_country_and_enter_phone_number")
                  }}</label>
                  <div class="d-flex align-center">
                    <v-combobox
                      v-model="fields.phoneCountryCode"
                      :items="phoneCountryCodes"
                      item-text="text"
                      item-value="value"
                      dense
                      hide-details
                      class="country-code-input mr-2"
                      style="max-width: 90px"
                      @input="handleCountryCodeInput"
                      :filter="filterCountryCodes"
                      placeholder="+1"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                    <v-text-field
                      v-model="fields.phoneNumber"
                      :rules="[rules.required, rules.phoneNumber]"
                      hide-details
                      dense
                      @input="formatPhoneNumber"
                      maxlength="14"
                      :placeholder="fields.phoneCountryCode === '+1' ? '555 555-5555' : '1511 234 567'"
                      class="mt-0 pt-0"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="7" md="7">
                  <v-text-field
                    disabled
                    v-model="fields.companyName"
                    :rules="[rules.required]"
                    :label="$t('v_account.t_company')"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    disabled
                    v-model="fields.email"
                    :rules="emailRules"
                    :label="$t('v_account.t_email')"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex ml-auto mr-3" cols="12">
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!formValid" color="primary" @click="onSubmit">{{ $t("t_update") }}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>

        <v-card class="mt-6">
          <v-toolbar elevation="0">
            <v-toolbar-title>{{ $t("v_account.t_change_password") }}</v-toolbar-title>
          </v-toolbar>
          <v-alert v-if="notificationPassword" type="error" class="ma-4">
            {{ notificationPassword }}
          </v-alert>
          <v-card-text>
            <v-form ref="changePasswordForm" v-model="formPasswordValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="creds.currentPassword"
                    :append-icon="showpw ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showpw ? 'text' : 'password'"
                    name="current-password"
                    :label="$t('v_account.t_current_password')"
                    :hint="$t('v_account.t_at_least_8_characters')"
                    @click:append="showpw = !showpw"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="creds.newPassword"
                    :append-icon="showpw ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="showpw ? 'text' : 'password'"
                    name="new-password"
                    :label="$t('v_account.t_new_password')"
                    :hint="$t('v_account.t_at_least_8_characters')"
                    @click:append="showpw = !showpw"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    block
                    v-model="creds.confirmPassword"
                    :append-icon="showpw ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min, passwordMatch]"
                    :type="showpw ? 'text' : 'password'"
                    name="confirm-password"
                    :label="$t('v_account.t_confirm_new_password')"
                    @click:append="showpw = !showpw"
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex ml-auto mr-3" cols="12">
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!formPasswordValid" color="primary" @click="onPasswordSubmit">{{
                    $t("v_account.t_change_password")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Trans } from "@/plugins/Translation";
import TheImageUploader from "@/components/TheImageUploader";
import TheLanguageSwitcher from "@/components/TheLanguageSwitcher";
import Helpers from "@/mixins/helpers";

export default {
  name: "Account",
  metaInfo: {
    title: "Account",
  },
  components: {
    TheImageUploader,
    TheLanguageSwitcher,
  },
  mixins: [Helpers],
  computed: {
    ...mapGetters("settings", ["settings"]),
    ...mapGetters("user", ["user"]),
    passwordMatch() {
      return () => this.creds.newPassword === this.creds.confirmPassword || "Password must match";
    },
  },
  data() {
    return {
      formValid: false,
      formPasswordValid: false,
      creds: {},
      fields: {
        languageCode: Trans.getUserLang().lang ? Trans.getUserLang().lang : "en-US",
      },
      emailRules: [(v) => !!v || "Required", (v) => /.+@.+\..+/.test(v) || "Email must be valid"],
      showpw: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        phoneNumber: (v) => {
          // Allow more flexible phone number formats
          // Basic format validation - numbers, spaces, and hyphens
          // Minimum 6 digits, maximum 15 digits (international standard)
          const digitsOnly = v.replace(/\D/g, "");
          const validFormat = /^[\d\s-]+$/.test(v);
          const validLength = digitsOnly.length >= 6 && digitsOnly.length <= 15;
          return (validFormat && validLength) || "Invalid phone number format";
        },
      },
      notificationPassword: null,
      notificationProfile: null,
      timeZones: [
        // Local Time Default
        { value: null, text: "Use Local Time", offset: "" },

        // US & Canada
        { value: "America/New_York", text: "Eastern Time (EST/EDT)", offset: "UTC-05:00" },
        { value: "America/Chicago", text: "Central Time (CST/CDT)", offset: "UTC-06:00" },
        { value: "America/Denver", text: "Mountain Time (MST/MDT)", offset: "UTC-07:00" },
        { value: "America/Los_Angeles", text: "Pacific Time (PST/PDT)", offset: "UTC-08:00" },
        { value: "America/Phoenix", text: "Arizona Time (MST, no DST)", offset: "UTC-07:00" },
        { value: "America/Anchorage", text: "Alaska Time (AKST/AKDT)", offset: "UTC-09:00" },
        { value: "Pacific/Honolulu", text: "Hawaii Time (HST)", offset: "UTC-10:00" },

        // Europe
        { value: "Europe/London", text: "UK Time (GMT/BST)", offset: "UTC+00:00" },
        { value: "Europe/Paris", text: "Central European Time (CET/CEST)", offset: "UTC+01:00" },
        { value: "Europe/Berlin", text: "German Time (CET/CEST)", offset: "UTC+01:00" },
        { value: "Europe/Moscow", text: "Moscow Time (MSK)", offset: "UTC+03:00" },

        // Asia
        { value: "Asia/Tokyo", text: "Japan Time (JST)", offset: "UTC+09:00" },
        { value: "Asia/Shanghai", text: "China Time (CST)", offset: "UTC+08:00" },
        { value: "Asia/Singapore", text: "Singapore Time (SGT)", offset: "UTC+08:00" },
        { value: "Asia/Dubai", text: "Gulf Time (GST)", offset: "UTC+04:00" },
        { value: "Asia/Kolkata", text: "India Time (IST)", offset: "UTC+05:30" },

        // Pacific
        { value: "Pacific/Auckland", text: "New Zealand Time (NZST/NZDT)", offset: "UTC+12:00" },
        { value: "Australia/Sydney", text: "Australian Eastern Time (AEST/AEDT)", offset: "UTC+10:00" },
        { value: "Australia/Perth", text: "Australian Western Time (AWST)", offset: "UTC+08:00" },

        // Other Major
        { value: "UTC", text: "Universal Time Coordinated (UTC)", offset: "UTC+00:00" },
      ],
      phoneCountryCodes: [
        { value: "+1", text: "+1 (USA/Canada)" },
        { value: "+44", text: "+44 (UK)" },
        { value: "+45", text: "+45 (Denmark)" },
        { value: "+31", text: "+31 (Netherlands)" },
        { value: "+299", text: "+299 (Greenland)" },
        { value: "+33", text: "+33 (France)" },
        { value: "+49", text: "+49 (Germany)" },
        { value: "+81", text: "+81 (Japan)" },
        { value: "+86", text: "+86 (China)" },
        { value: "+91", text: "+91 (India)" },
        { value: "+61", text: "+61 (Australia)" },
        { value: "+52", text: "+52 (Mexico)" },
        { value: "+55", text: "+55 (Brazil)" },
        { value: "+7", text: "+7 (Russia)" },
        { value: "+65", text: "+65 (Singapore)" },
        { value: "+971", text: "+971 (UAE)" },
        { value: "+34", text: "+34 (Spain)" },
        { value: "+39", text: "+39 (Italy)" },
        { value: "+46", text: "+46 (Sweden)" },
        { value: "+41", text: "+41 (Switzerland)" },
        { value: "+61", text: "+61 (Australia)" },
        { value: "+63", text: "+63 (Philippines)" },
        { value: "+64", text: "+64 (New Zealand)" },
        { value: "+351", text: "+351 (Portugal)" },
      ],
    };
  },
  methods: {
    ...mapActions("auth", ["changePassword", "logout"]),
    ...mapActions("settings", ["setLang", "getUser", "updateUserProfile"]),
    ...mapMutations("settings", ["toggleDarkMode", "toggleTimeFormat", "setTimeZone"]),
    async onSubmit() {
      if (this.$refs.registerForm.validate()) {
        let user = {};
        user.id = this.fields.id;
        user.firstName = this.fields.firstName;
        user.lastName = this.fields.lastName;
        // Combine country code with cleaned phone number
        const cleanedPhoneNumber = this.fields.phoneNumber.replace(/\D/g, "");
        user.phoneNumber = `${this.fields.phoneCountryCode}${cleanedPhoneNumber}`;

        await this.updateUserProfile(user);

        this.notificationProfile = "";
        this.$snackbar.showMessage({
          content: this.$t("c_snackbar.t_profile_updated"),
          color: "success",
          timeout: "",
        });
      }
    },
    async onPasswordSubmit() {
      if (this.$refs.changePasswordForm.validate()) {
        this.creds.email = this.fields.email;

        let params = {};
        params.oldPassword = this.creds.currentPassword;
        params.newPassword = this.creds.newPassword;

        await this.changePassword(params);

        this.notificationPassword = "";
        this.$snackbar.showMessage({
          content: this.$t("c_snackbar.t_success_password_changed"),
          color: "success",
          timeout: "",
        });

        this.logout();
      }
    },
    getUserProfile() {
      this.getUser().then((r) => {
        const data = { ...r.data }; // Create a copy of the data

        // Handle phone number parsing before setting fields
        if (data.phoneNumber && data.phoneNumber.startsWith("+")) {
          // Find matching country code by checking known codes
          const matchingCode = this.phoneCountryCodes
            .map((code) => code.value)
            .sort((a, b) => b.length - a.length) // Sort by length descending to match longer codes first
            .find((code) => data.phoneNumber.startsWith(code));

          if (matchingCode) {
            // Set the country code
            data.phoneCountryCode = matchingCode;

            // Get the rest of the number
            const numberWithoutCode = data.phoneNumber.substring(matchingCode.length);
            const cleaned = numberWithoutCode.replace(/\D/g, "");

            // Format based on country code
            if (matchingCode === "+1") {
              // North American format
              if (cleaned.length >= 10) {
                data.phoneNumber = `${cleaned.substring(0, 3)} ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`;
              }
            } else if (matchingCode === "+55") {
              // Brazil format: XX XXXXX-XXXX
              if (cleaned.length >= 11) {
                data.phoneNumber = `${cleaned.substring(0, 2)} ${cleaned.substring(2, 7)}-${cleaned.substring(7)}`;
              }
            } else {
              // International format: groups of numbers with spaces
              let formatted = "";
              let i = 0;
              while (i < cleaned.length) {
                if (i === 0) {
                  formatted += cleaned.substring(i, i + 4);
                  i += 4;
                } else {
                  formatted += " " + cleaned.substring(i, i + 3);
                  i += 3;
                }
              }
              data.phoneNumber = formatted;
            }
          } else {
            // If no matching code found, default to +1
            data.phoneCountryCode = "+1";
          }
        } else {
          // Default to +1 if no country code exists
          data.phoneCountryCode = "+1";
        }

        // Set all fields after parsing
        this.fields = data;
      });
    },
    changeTimeZone(timeZone) {
      this.setTimeZone(timeZone);
      this.$snackbar.showMessage({
        content: this.$t("c_snackbar.t_success_settings_saved"),
        color: "success",
        timeout: "",
      });
    },
    changeTimeFormat() {
      this.toggleTimeFormat();
      this.$snackbar.showMessage({
        content: this.$t("c_snackbar.t_success_settings_saved"),
        color: "success",
        timeout: "",
      });
    },
    changeDarkMode() {
      this.toggleDarkMode();
      this.$snackbar.showMessage({
        content: this.$t("c_snackbar.t_success_settings_saved"),
        color: "success",
        timeout: "",
      });
    },
    formatPhoneNumber() {
      if (!this.fields.phoneNumber) return;

      // Remove all non-numeric characters except the last entered character
      let value = this.fields.phoneNumber;
      let lastChar = value.slice(-1);
      let cleaned = value.slice(0, -1).replace(/[^\d]/g, "") + lastChar;

      // Remove any non-numeric characters for processing
      let digitsOnly = cleaned.replace(/\D/g, "");

      // Format based on country code
      if (this.fields.phoneCountryCode === "+1") {
        // North American format XXX XXX-XXXX
        if (digitsOnly.length >= 10) {
          this.fields.phoneNumber = `${digitsOnly.substring(0, 3)} ${digitsOnly.substring(3, 6)}-${digitsOnly.substring(
            6,
            10,
          )}`;
        } else if (digitsOnly.length >= 6) {
          this.fields.phoneNumber = `${digitsOnly.substring(0, 3)} ${digitsOnly.substring(3, 6)}-${digitsOnly.substring(
            6,
          )}`;
        } else if (digitsOnly.length >= 3) {
          this.fields.phoneNumber = `${digitsOnly.substring(0, 3)} ${digitsOnly.substring(3)}`;
        } else if (digitsOnly.length > 0) {
          this.fields.phoneNumber = digitsOnly;
        }
      } else if (this.fields.phoneCountryCode === "+55") {
        // Brazil format: XX XXXXX-XXXX
        if (digitsOnly.length >= 11) {
          this.fields.phoneNumber = `${digitsOnly.substring(0, 2)} ${digitsOnly.substring(2, 7)}-${digitsOnly.substring(
            7,
          )}`;
        } else if (digitsOnly.length >= 7) {
          this.fields.phoneNumber = `${digitsOnly.substring(0, 2)} ${digitsOnly.substring(2, 7)}-${digitsOnly.substring(
            7,
          )}`;
        } else if (digitsOnly.length >= 2) {
          this.fields.phoneNumber = `${digitsOnly.substring(0, 2)} ${digitsOnly.substring(2)}`;
        } else {
          this.fields.phoneNumber = digitsOnly;
        }
      } else {
        // International format: groups of numbers with spaces
        let formatted = "";
        let i = 0;
        while (i < digitsOnly.length) {
          if (i === 0) {
            formatted += digitsOnly.substring(i, i + 4);
            i += 4;
          } else {
            formatted += " " + digitsOnly.substring(i, i + 3);
            i += 3;
          }
        }
        this.fields.phoneNumber = formatted;
      }
    },
    handleCountryCodeInput(value) {
      // Handle both object (from select) and string (from manual input) values
      if (typeof value === "object" && value !== null) {
        this.fields.phoneCountryCode = value.value;
      } else {
        // Ensure the country code always starts with +
        if (!value.startsWith("+")) {
          value = "+" + value.replace(/[^\d]/g, "");
        }
        // Remove any non-numeric characters after the +
        this.fields.phoneCountryCode = "+" + value.substring(1).replace(/[^\d]/g, "");
      }
      this.formatPhoneNumber();
    },
    filterCountryCodes(item, queryText) {
      const text = item.text.toLowerCase();
      const value = item.value.toLowerCase();
      const query = queryText.toLowerCase();
      return text.includes(query) || value.includes(query);
    },
  },
  mounted() {
    this.getUserProfile();
    if (!this.fields.phoneCountryCode) {
      this.fields.phoneCountryCode = "+1";
    }
    if (!this.settings.timeZone) {
      this.setTimeZone(null); // Will set to "Use Local Time" option
    }
  },
};
</script>

<style scoped>
.country-code-select ::v-deep .v-input__slot {
  min-height: 32px !important;
  margin-bottom: 0;
}

.country-code-select ::v-deep .v-select__slot {
  height: 32px;
}

.country-code-select ::v-deep .v-input__append-inner {
  margin-top: 2px !important;
}

.v-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
  display: block;
}

.theme--dark .v-label {
  color: rgba(255, 255, 255, 0.7);
}
</style>
